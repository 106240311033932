/**
 * ShowcaseCardLandscape
 * 
 * @Author: Focci
 * @Date: 2023-11-03 15:05:46
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-03 15:05:46
 */

import PropTypes from 'prop-types'
// import dynamic from 'next/dynamic'
import UserTracking from '@comp/user-tracking'
import useTracking from '@hook/useTracking'
import { houseToGta } from '@lib/utils'
import PropertyIcons from '@page/common/card/property/PropertyIcons'
import { get } from 'lodash-es'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import { LinkTo } from '@comp/LinkTo'
import { useCallback, useEffect } from 'react'
import { handlePushTrack } from '../lib'
import AgentInfo from './AgentInfo'
import CoverInfo from './CoverInfo'
import { getAdData } from './lib'

// const Favorite = dynamic(() => import('@page/common/favorite'), { ssr: false })

export default function ShowcaseCardLandscape({ data, pageType }) {
  const locale = useLocale()
  const ts = useTranslations('search')
  const { pushTrack } = useTracking()

  const { house, office, url } = getAdData(data, locale)
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      handlePushTrack(data, pushTrack, 'click_ad')
    },
    [data, pushTrack]
  )

  useEffect(() => {
    data && handlePushTrack(data, pushTrack, 'show_ad')
  }, [data, pushTrack])

  return (
    <>
      <UserTracking
        auOnly
        eventName="homely_card_view"
        params={houseToGta(get(data, 'house'), pageType, 'ad_house')}
      />
      <UserTracking
        auOnly
        eventName="homely_photo_view"
        params={houseToGta(get(data, 'house'), pageType, 'ad_house')}
      />
      <LinkTo href={url}>
        <div
          className="flex flex-col md:flex-row gap-4 bg-white p-4 max-w-[660px] mx-auto border rounded-md max-w-full"
          onClick={handleClick}
          onKeyPress={handleClick}
          tabIndex="0"
          role="button"
        >
          <div className="w-full md:w-[300px] shrink-0">
            <CoverInfo
              house={house}
              className="w-full h-[225px] rounded-md overflow-hidden"
            />
          </div>
          <div className="flex-auto flex flex-col overflow-hidden">
            <div className="mt-1 px-2">
              <div className="text-f.9 font-medium leading-tight line-clamp-2">
                {house.h1 || house.street}
              </div>
              <div className="leading-tight line-clamp-1 my-1">
                {house.address}
              </div>
              <PropertyIcons iconWidth=".8rem" data={house} />
            </div>
            <div className="flex-auto" />
            <AgentInfo agents={house.agents}>
              <span className="border px-0.5 text-meta text-f.6 -mr-2">
                {ts('promote')}
              </span>
            </AgentInfo>
            <div
              className="h-[35px] rounded-b-md flex items-center justify-center px-2 mt-2"
              style={{
                backgroundColor: office?.officeColor,
                color: office?.fontColor
              }}
            >
              <Image
                priority
                src={office?.oss_avatar || ''}
                alt={office?.name}
                width={100}
                height={30}
                className="object-contain max-h-[30px] object-center"
              />
            </div>
          </div>
        </div>
      </LinkTo>
    </>
  )
}

ShowcaseCardLandscape.propTypes = {
  data: PropTypes.object,
}
