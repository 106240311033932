/**
 * 广告
 * 
 * @Author: Focci
 * @Date: 2023-11-02 15:24:56
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-02 15:24:56
 */

'use client'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isArray } from 'lodash-es'
import { fetchGet } from '@lib/fetch/client'
import { useEffect, useMemo } from 'react'
import { useAsyncFn } from 'react-use'
import { initialAd } from './lib'
import AdvertItems from './AdvertItems'

export default function Advertisement({
  subtile = false,
  className = '',
  style = null,
  adData, // 直接指定广告数据
  locationId,
  width = 300,
  height = 300,
  fill = false, // 是否充满容器
  AdvertItemCard,
  onLoad,
  onChange,
  pageType
}) {
  const [locationState, fetchLocation] = useAsyncFn(async () => {
    if (locationId) {
      return fetchGet(`multiple-ads/${locationId}`, { type: 2 })
    }
    return null
  }, [locationId])
  
  const advertData = useMemo(
    () => (!locationId ? adData : initialAd(locationState.value)?.[locationId]),
    [adData, locationId, locationState]
  )

  useEffect(() => {
    if (!locationId) return
    fetchLocation().then(onLoad)
  }, [fetchLocation, onLoad, locationId])

  // 判断是否存在数据
  if(!locationState.loading && isArray(advertData) && advertData.length === 0) {
    return null
  }

  return (
    <div
      className={clsx([
        'relative mx-auto group/advert max-w-full',
        subtile && 'bg-cf0',
        className
      ])}
      style={{
        width: fill ? '100%' : `${width}px`,
        height: fill ? '100%' : `${height}px`,
        aspectRatio: `${width} / ${height}`,
        ...style,
      }}
    >
      <AdvertItems
        data={advertData}
        width={width}
        height={height}
        fill={fill}
        AdvertItemCard={AdvertItemCard}
        pageType={pageType}
        onChange={onChange}
      />
    </div>
  )
}

Advertisement.propTypes = {
  subtile: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  adData: PropTypes.array,
  locationId: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.bool,
  AdvertItemCard: PropTypes.func,
  onLoad: PropTypes.func,
}
