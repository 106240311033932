/**
 * 推荐房源广告
 * 尺寸 300 * 600
 * 
 * @Author: Focci
 * @Date: 2023-11-03 15:01:21
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-03 15:01:21
 */

'use client'

import PropTypes from 'prop-types'
import Image from 'next/image'
import { LinkTo } from '@comp/LinkTo'
import UserTracking from '@comp/user-tracking'
import useTracking from '@hook/useTracking'
import PropertyIcons from '@page/common/card/property/PropertyIcons'
import { useLocale } from 'next-intl'
import { houseToGta } from '@lib/utils'
import { get, isArray } from 'lodash-es'
import { useCallback, useEffect } from 'react'
import { handlePushTrack } from '../lib'
import AgentInfo from './AgentInfo'
import CoverInfo from './CoverInfo'
import PromoteInfo from './PromoteInfo'
import { getAdData } from './lib'

export default function ShowcaseCardVertical({ data, width, height, pageType }) {
  const locale = useLocale()
  const { pushTrack } = useTracking()
  const { house, office, url } = getAdData(data, locale)

  const handleClick = useCallback(
    () => handlePushTrack(data, pushTrack, 'click_ad'),
    [data, pushTrack]
  )

  useEffect(() => {
    data && handlePushTrack(data, pushTrack, 'show_ad')
  }, [data, pushTrack])

  return (
    <>
      <UserTracking
        auOnly
        eventName="homely_card_view"
        params={houseToGta(get(data, 'house'), pageType, 'ad_house')}
      />
      <UserTracking
        auOnly
        eventName="homely_photo_view"
        params={houseToGta(get(data, 'house'), pageType, 'ad_house')}
      />
      <LinkTo href={url} className="flex flex-col flex-auto">
        <div
          className="
            bg-white rounded-md overflow-hidden shadow-simple 
            flex flex-col gap-2
          "
          style={{ width: `${width}px`, height: `${height}px` }}
          onClick={handleClick}
          onKeyPress={handleClick}
          tabIndex="0"
          role="button"
        >
          <CoverInfo house={house} className="w-full h-[225px]" />
          <div className="grid grid-cols-2 gap-x-[2px] -mt-2">
            <div className="mt-[2px] aspect-[150 / 110] h-[110px]">
              <Image
                priority
                src={house.images?.[1] || ''}
                alt={house.address}
                width={150}
                height={110}
                className="object-cover w-full h-full"
              />
            </div>
            <div className="mt-[2px] aspect-[150 / 110] h-[110px]">
              <Image
                priority
                src={house.images?.[2] || ''}
                alt={house.address}
                width={150}
                height={110}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="mt-1 px-2">
            <div className="text-f.9 font-medium leading-tight my-2 line-clamp-2">
              {isArray(house.address) ? house.address.join(', ') : house.address}
            </div>
            <PropertyIcons data={house} />
          </div>
          <AgentInfo agents={house.agents} />
          <div className="flex-auto" />
          <div
            className="flex items-center justify-center h-10 px-2"
            style={{
              backgroundColor: office.officeColor,
              color: office.fontColor
            }}
          >
            {office?.oss_avatar && (
              <Image
                priority
                src={office.oss_avatar || ''}
                alt={office.name}
                width={100}
                height={30}
                className="object-contain max-w-full max-h-[30px]"
              />
            )}
          </div>
        </div>
      </LinkTo>
      <PromoteInfo street={house.street} />
    </>
  )
}

ShowcaseCardVertical.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
}
