/**
 * 推荐房源广告
 * 尺寸 300 * 250
 * 
 * @Author: Focci
 * @Date: 2023-11-03 15:04:17
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-03 15:04:17
 */

import PropTypes from 'prop-types'
import PropertyIcons from '@page/common/card/property/PropertyIcons'
import Image from 'next/image'
import { LinkTo } from '@comp/LinkTo'
// import clsx from 'clsx'
import UserTracking from '@comp/user-tracking'
import useTracking from '@hook/useTracking'
import { houseToGta } from '@lib/utils'
import { useLocale } from 'next-intl'
import { get } from 'lodash-es'
import { useCallback, useEffect } from 'react'
import { handlePushTrack } from '../lib'
import CoverInfo from './CoverInfo'
import PromoteInfo from './PromoteInfo'
import { getAdData } from './lib'

export default function ShowcaseCardRectangle({ data, width, height, pageType }) {
  const locale = useLocale()
  const { pushTrack } = useTracking()

  const { house, office, url, agentName, agents } = getAdData(data, locale)

  const handleClick = useCallback(
    () => handlePushTrack(data, pushTrack, 'click_ad'),
    [data, pushTrack]
  )

  useEffect(() => {
    data && handlePushTrack(data, pushTrack, 'show_ad')
  }, [data, pushTrack])

  return (
    <>
      <UserTracking
        auOnly
        eventName="homely_card_view"
        params={houseToGta(get(data, 'house'), pageType, 'ad_house')}
      />
      <UserTracking
        auOnly
        eventName="homely_photo_view"
        params={houseToGta(get(data, 'house'), pageType, 'ad_house')}
      />
      <LinkTo href={url} style={{ width: `${width}px`, height: `${height}px` }}>
        <div
          className="
          bg-white rounded-md overflow-hidden shadow-simple 
          flex flex-col
        "
          onClick={handleClick}
          onKeyPress={handleClick}
          tabIndex="0"
          role="button"
        >
          <CoverInfo
            house={house}
            className="w-full h-[200px] overflow-hidden"
          >
            <div className="text-f.6 -mt-1">
              <PropertyIcons iconWidth=".7rem" data={house} />
            </div>
          </CoverInfo>
          <div
            className="flex-auto flex items-center justify-between h-6 px-2"
            style={{
              backgroundColor: office?.officeColor,
              color: office?.fontColor
            }}
          >
            <div>
              {office?.oss_avatar && (
                <Image
                  priority
                  src={office.oss_avatar || ''}
                  alt={office.name}
                  width={80}
                  height={20}
                />
              )}
            </div>
            <div className="relative min-h-full">
              {agents.length < 2 && (
                <span className="mr-12 text-f.6 text-medium">
                  {agentName}
                </span>
              )}
              <div className="absolute bottom-1 right-0 z-10">
                <div className="flex items-center gap-x-0.5">
                  {agents.map((agent) => (
                    <div
                      key={agent.name}
                      className="w-10 h-10 border border-white bg-white rounded-full"
                    >
                      <Image
                        priority
                        src={agent.icon || ''}
                        alt={agent.name}
                        width={50}
                        height={50}
                        className="w-full h-full rounded-full object-cover object-top"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PromoteInfo street={house.street} />
      </LinkTo>
    </>
  )
}

ShowcaseCardRectangle.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
}
